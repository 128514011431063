<template>
<div>

    <b-card>
        <!-- input search -->
        <div class="custom-search d-flex justify-content-end">
            <b-form-group>
                <div class="d-flex align-items-center">
                    <label class="mr-1">Search</label>
                    <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" />
                </div>
            </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table :columns="columns" :rows="rows" :rtl="direction" :search-options="{
        enabled: true,
        externalQuery: searchTerm }" :pagination-options="{
        enabled: true,
        perPage:pageLength
      }">
            <template slot="table-row" slot-scope="props">
                <!-- Column: Name -->
                <div v-if="props.column.field === 'fullName'" class="text-nowrap">
                    <b-avatar :src="props.row.avatar" class="mx-1" />
                    <span class="text-nowrap">{{ props.row.fullName }}</span>
                </div>

                <!-- Column: Status -->
             

                <!-- Column: Common -->
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap">
                            Showing 1 to
                        </span>
                        <b-form-select v-model="pageLength" :options="['3','5','10']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                        <span class="text-nowrap "> of {{ props.total }} entries </span>
                    </div>
                    <div>
                        <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value)=>props.pageChanged({currentPage:value})">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
        </vue-good-table>
    </b-card>
</div>
</template>

<script>
import {
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard
} from 'bootstrap-vue'
import {
    VueGoodTable
} from 'vue-good-table'
import store from '@/store/index'
import $ from 'jquery'
export default {
    components: {
        VueGoodTable,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BCard
    },
    data() {
        return {
            pageLength: 10,
            dir: false,
            columns: [{
                    label: 'Phone Number',
                    field: 'msisdn',
                },
                {
                    label: 'ShortCode',
                    field: 'short_code'
                },
                {
                    label: 'Status',
                    field: 'status',
                },

                {
                    label: 'Date',
                    field: 'created_at',
                },
            ],
            rows: [],
            searchTerm: '',
            status: [{
                13: 'Active',
                11: 'Pending',
                12: 'Rejected',
            }, {
                13: 'light-success',
                11: 'light-primary',
                12: 'light-danger',
            }],
        }
    },
    computed: {
        statusVariant() {
            const statusColor = {
                /* eslint-disable key-spacing */
                Active: 'light-success',
                Pending: 'light-primary',
                Rejected: 'light-danger',
                /* eslint-enable key-spacing */
            }

            return status => statusColor[status]
        },
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },
    mounted() {
        let vm = this
        vm.getAllblackList()
    },
    methods: {
        getAllblackList() {
            let vm = this
            $.get({
                url: store.state.rootUrl + 'account/v1/view/blacklist',
                type: "get",
                async: true,
                data: {
                    shortCode: "",
                    offset: "",
                    sort: "",
                    export: "",
                    limit: 5000,
                    start: "",
                    end: "",
                    clientId: vm.$cookies.get("clientData").clientId
                },
                crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                    "X-Authorization-Key": vm.$cookies.get("token"),
                    "X-Requested-With": "XMLHttpRequest",
                },
                success: function (response, status, jQxhr) {
                    if (response.data.code != 200) {
                        vm.rows = []
                    } else {
                        let result = response.data.data.data;
                        vm.rows = result
                        console.log("Items are here " + JSON.stringify(vm.rows))

                    }

                },
                error: function (jQxhr, status, error) {
                    vm.rows = []
                    if (jQxhr.responseJSON.data.code == 401 || jQxhr.responseJSON.data.code == 500 || jQxhr.responseJSON.data.code == 403 || jQxhr.responseJSON.data.code == 404) {
                        vm.$cookies.remove("token");
                        vm.$cookies.remove("clientData");
                        // Redirect to login page
                        vm.$router.push({ name: 'login' })
                        vm.$toast({
                            component: ToastificationContent,
                            props: {
                                title: jQxhr.responseJSON.data.message,
                                icon: 'EditIcon',
                                variant: 'failed',
                            },
                        })
                    }
                }
            });
        }
    },
    filters: {
        formatDate(value) {
            if (!value) return "";
            return moment(value).format("DD MMM YYYY");
        },
        shortcodeStatus(value) {
            if (value == 11) {
                return "Pending"
            } else if (value == 4) {
                return "Active"
            } else {
                return "Disabled"
            }
        },
        shortCodeStatusColor(value) {
            if (value == 11) {
                return "light-warning"
            } else if (value == 4) {
                return "light-success"
            } else {
                return "light-danger"
            }
        }
    }

}
</script>
